<template>
	<div
		class="full-height"
	>
		<div
			class="justify-space-between "
		>
			<div class="flex-1 mr-10 bg-white pa-10">
				<table
					class="table"
				>
					<col width="120px" />
					<col width="auto" />

					<tbody>
					<tr>
						<th>제목</th>
						<td>
							<input
								v-model="item.popup_name"
								class="input-box pa-10 width-100"
								placeholder="제목을 입력하세요"
							/>
						</td>
					</tr>
					<tr>
						<th>기간</th>
						<td>
							<Date_picker
								v-if="item.uid"
								:date="item.sDate?.slice(0, 10)"
								@click="setDate($event,'sDate')"
								class="mr-10"
							></Date_picker>

							~

							<Date_picker
								v-if="item.uid"
								:date="item.eDate?.slice(0, 10)"
								@click="setDate($event,'eDate')"
								class="mr-10"
							></Date_picker>
						</td>
					</tr>
					<tr>
						<th>내용</th>
						<td>
							<textarea
								v-model="item.popup_contents"
								class="input-box pa-10"
								placeholder="내용을 입력하세요"
							/>
						</td>
					</tr>
					<tr>
						<th>
							<span class="vertical-middle">팝업 이미지</span>
						</th>
						<td>
							<img :src="item.popup_img" class="width-100"/>
							<div
								v-for="(file, index) in file_items"
								:key="'file_' + index"
								class="justify-space-between mb-10 mt-10"
							>
								<label
									class="flex-1 box pa-5 mr-10 text-left"
								>
									<v-icon
										class="color-icon"
									>mdi mdi-image</v-icon>
									{{ file.file_name }}
									<input
										v-show="false"
										type="file"
										placeholder="배너 이미지"
										class="input-box"
										maxlength="250"
										@change="setFile($event, index)"
										accept="image/*"
									/>
								</label>
								<input
									v-model="file.popup_link"
									class="flex-1 box pa-5 mr-10"
									placeholder="배너 링크"
								/>
								<select
									v-model="file.is_out"
									class="box pa-5 mr-10"
								>
									<option value="0">외부 링크</option>
									<option value="1">내부 링크</option>
								</select>
								<v-icon
									class="color-red"
									@click="removeFile(index)"
								>mdi mdi-close-box-outline</v-icon>
							</div>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
			<div class="flex-1">
				<div class="">
				</div>
			</div>
		</div>

		<div class="mt-30 text-center">
			<button
				class="pa-10 box btn-blue mr-10"
				@click="save"
			>저장</button>
			<button
				class="pa-10 box btn-gray"
				@click="$emit('goBack')"
			>목록 </button>
		</div>
	</div>
</template>


<script>

import Date_picker from "../../components/DatePicker";
export default {
	name: 'PopupItem'
	,props: ['Axios', 'user', 'codes', 'rules', 'TOKEN']
	,components: {Date_picker}
	,data: function(){
		return {
			program: {
				name: '팝업 상세정보'
				,top: true
				,title: true
				,bottom: false
			}
			,item_board: {

			}
			,item: {
				popup_name: ''
				,type: 'main'
				, sDate: ''
				, eDate: ''
			}
			,files: [
				{ file: null, file_name: '파일을 선택하세요', link: '', is_out: '1'}
			]
		}
	}
	,computed: {
		file_items: function(){
			return this.files.filter(function(item){
				if(!item.file_name){
					item.file_name = '파일을 선택하세요'
				}
				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getPopupInfo'
					,data: {
						popup_uid: this.$route.params.popup_uid
					}
				})

				if(result.success){
					this.item = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,save: async function(){
			this.$bus.$emit('on', true)

			this.item.files = JSON.stringify(this.files)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postPopupModify'
					,data: this.item
				})

				if(result.success){
					this.toBack()
					this.$bus.$emit('notify', { type: 'success', message: result.message })
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,setFile: function(e, index){

			let file = e.target.files[0]

			this.$set(this.item, 'popup_file' + index, file)
			this.$set(this.files[index], 'file_name', file.name)
		}
		,toBack: function(){
			this.$emit('goBack')
		}
		,addFiles: function(){
			this.files.push({
				file: null
				,file_name: ''
				,popup_link: ''
				,is_out: 1
			})
		}
		,removeFile: function(index){
			if(this.files.length > 1){
				this.$delete(this.files, index)
				this.$delete(this.item['popup_file' + index])
			}
		}
		, setDate: function(date, type){
			console.log(date, type)
			this.item[type] = date
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.flex-title { flex: 0 0 120px; align-items: center}
label { display: inline-block}
</style>